
import $ from 'jquery';
import { createHash } from 'crypto';

$(document).ready(function(){
    console.log('mainDesktop JS bereit');

// ---------------------------------------------------------------------------------------------- //
// ---------------------------------------------------------------------------------------------- //
//                                          GLOBAL VARIABLEN
// ---------------------------------------------------------------------------------------------- //
// ---------------------------------------------------------------------------------------------- //



    var viewPortWidth;
    var viewPortHeight;
    var containerFullscreen = document.getElementById('background-stage');



    var currentClient           = function(){
        var clientClass = $(body).attr('class');
        return clientClass;
    }


// ---------------------------------------------------------------------------------------------- //
// ---------------------------------------------------------------------------------------------- //
//                                         Initialisierungen
// ---------------------------------------------------------------------------------------------- //
// ---------------------------------------------------------------------------------------------- //
    
    $(window).resize(function(){
        if ( $(body).hasClass('horizontal') || $(body).hasClass('vertical')){
            HANDLE_VIEWPORT_LANDSCAPE();
            HANDLE_HEADLINE_STARTSLIDER_H4_OBJECTS();
        };
    });
    
    // init_fullscreen();

    
    
    
    

// ---------------------------------------------------------------------------------------------- //
// ---------------------------------------------------------------------------------------------- //
//                                               EVENTS
// ---------------------------------------------------------------------------------------------- //
// ---------------------------------------------------------------------------------------------- //

  


    





   






// ---------------------------------------------------------------------------------------------- //
// ---------------------------------------------------------------------------------------------- //
//                                               FUNKTIONEN
// ---------------------------------------------------------------------------------------------- //
// ---------------------------------------------------------------------------------------------- //

    function init_fullscreen(){

        console.log('init_fullscreen');

        containerFullscreen.addEventListener('click', function () {

            enterFullscreen( document.documentElement );

            // Do some Stuff

            
        });
    };

    // Fullscreen aktivieren
    function enterFullscreen(element) {

        console.log('enterFullscrren');

        if(element.requestFullscreen) {
            element.requestFullscreen();
        } else if(element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if(element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if(element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    };








});

